import React from "react"
import styled from "styled-components";

const Container = styled.div`
max-width: 760px;
margin: 2em auto;
font-family: "Open Sans";
line-height: 1.5;
font-size: 17px;
li {
    list-style: circle;
}
`

export default function Layout({ children }) {
  return (
    <Container>
      {children}
    </Container>
  )
}