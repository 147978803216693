import React from "react" 
import { StaticQuery, graphql } from 'gatsby'
import styled from "styled-components";
import { StaticImage } from "gatsby-plugin-image"
import { Link } from "gatsby"

const Nav = styled.header`
    max-width: 1440px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-content: center;
    background: white;
    font-family: sans-serif;
    padding: 0 2em;
    flex-wrap: wrap;

    @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
    }

    h1 {
      display:none;
      visibility: hidden;
    }
    menu {
      a {
      color: #2a627a; 
      margin-left: 1em;
      @media (max-width: 768px) {
        margin-left: 0;
      
    }
    }
    }
`

const NavBar = styled.div`
    display: flex;
    

    @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;

    }

    a {
      color: #2a627a; 
      margin-left: 1em;
      text-decoration: none;
      @media (max-width: 768px) {
        margin-left: 0;
        margin-bottom: 0.5em;
    }
  }
`

const TitleAndDescription = ({data}) => {
    const title = data.site.siteMetadata.title
  
    return (
     
      <Nav>
        <div>
        <Link to="/">
          <StaticImage src="../images/logo.png" alt="lebensfluss" placeholder="blurred"
        layout="fixed"
        width={300} />
        </Link>
        <h1>{title}</h1>
        </div>
        <NavBar>
          <Link to="/therapy">Kunsttherapie</Link>
          <Link to="/counseling">Lebens- und Sozialberatung</Link>
          <Link to="/me">Über mich</Link>
        </NavBar>
      </Nav>
      
    )
  }
  
  const Header = () => {
    return (
      <StaticQuery
      query={graphql`
        {
          site {
            siteMetadata {
              title
              description
            }
          }
        }
      `}
      render={data => <TitleAndDescription data={data} />}
    ></StaticQuery>
    )
  }

  export default Header