import React from "react"
import styled from "styled-components";
import { Link } from "gatsby"

const StyledFooter = styled.footer`
      background-color: #3d4469;
      padding: 1em;
      color: white; 
      display: flex;
      flex-wrap: wrap;
      justify-content: space-evenly;

      a {
        color:white;
        text-decoration: auto;
      }

    @media (max-width: 768px) {
    flex-direction: column;
  
    }
`

export default function Footer() {
  return (
    <StyledFooter>
      <div>
      <p><span className="copyright">© Copyright by Silvija Podgorac 2021</span> </p>
      <p><Link to="/impressum">Impressum</Link></p>
      <p><Link to="/datenschutz">Datenschutz</Link> </p>
      </div>
      <div>
      <p>Silvija Podgorac Dipl. Kunsttherapeutin Dipl. Lebens- und Sozialberaterin </p> 
      <p><a style={{color: "white"}} href="tel:+4369911827092">0699/11827092</a></p>
      <p><a style={{color: "white"}} href="mailto:s.podgorac@me.com">s.podgorac@me.com</a></p>
      <p><a style={{color: "white"}} href="https://www.google.com/maps/dir//Hollgasse+6,+1050+Wien/@48.1825117,16.3601981,21z/data=!4m5!3m4!1s0x476da82d730381a1:0x15ffd654d5caf6b2!8m2!3d48.1825081!4d16.3603349" rel="noreferrer" target="_blank">Praxiszentrum Hollgasse 6/3/R01 1050 Wien</a></p>
      </div>
    </StyledFooter>
  )
}